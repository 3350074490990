import React from 'react';
import { Link } from 'gatsby';
import LayoutEN from '../components/layout.en';
import Seo from '../components/seo.es';

export default function AvisoLegal() {
	return (
		<LayoutEN>
			<Seo title='Aviso Legal' />
			<div
				style={{
					margin: '0 auto',
					width: '80%',
					maxWidth: '800px',
					padding: '60px 0',
				}}
			>
				<h1 style={{ marginTop: '3rem' }}>Aviso Legal</h1>
				<p>Condiciones de Uso del Sitio Web</p>
				<p>Versión 2.0. Última modificación: 2 de agosto de 2021</p>
				<p>Entidad: KUFLOW, S.L. (en adelante KUFLOW). CIF: B02951978</p>
				<h2>1. Objeto y aceptación</h2>
				<p>
					El presente aviso legal regula el uso del sitio Web: www.kuflow.com.
					La navegación por la página www.kuflow.com. atribuye la condición de
					usuario de la misma e implica la aceptación plena y sin reservas de
					todas y cada una de las disposiciones incluidas en este Aviso Legal,
					que pueden sufrir modificaciones. El usuario se obliga a hacer un uso
					correcto del sitio Web de conformidad con las leyes, la buena fe, el
					orden público, los usos del tráfico y el presente Aviso Legal. El
					Usuario responderá frente a KUFLOW y frente a terceros, de los daños y
					perjuicios que pudieran causarse como consecuencia del incumplimiento
					de dicha obligación.
				</p>
				<h2>2. Condiciones de acceso y utilización </h2>
				<p>
					El sitio Web y sus servicios son de acceso libre y gratuito. El
					Usuario garantiza la autenticidad y actualidad de todos aquellos datos
					que comunique a KUFLOW y será el único responsable de las
					manifestaciones falsas o inexactas que realice.
				</p>
				<p>
					El Usuario se compromete expresamente a hacer un uso adecuado de los
					contenidos y servicios ofrecidos y a no emplearlos para: a) Difundir
					contenidos delictivos, violentos, pornográficos, racistas, xenófobo,
					ofensivos, de apología del terrorismo o, en general, contrarios a la
					ley o al orden público. b) Introducir en la red virus informáticos o
					realizar actuaciones susceptibles de alterar, estropear, interrumpir o
					generar errores o daños en los documentos electrónicos, datos o
					sistemas físicos y lógicos de KUFLOW o de terceras personas; así como
					obstaculizar el acceso de otros Usuarios al sitio Web y a sus
					servicios. c) Intentar acceder a áreas restringidas de los sistemas
					informáticos de KUFLOW o de terceros y, en su caso, extraer
					información. d) Vulnerar los derechos de propiedad intelectual o
					industrial, así como violar la confidencialidad de la información de
					KUFLOW o de terceros. e) Suplantar la identidad de otro Usuario, de
					las administraciones públicas o de un tercero. f) Reproducir, copiar,
					distribuir, poner a disposición o de cualquier otra forma comunicar
					públicamente, transformar o modificar los Contenidos, a menos que se
					cuente con la autorización del titular de los correspondientes
					derechos o ello resulte legalmente permitido g) Recabar datos con
					finalidad publicitaria y de remitir publicidad de cualquier clase y
					comunicaciones con fines de venta u otras de naturaleza comercial sin
					que medie su previa solicitud o consentimiento.
				</p>
				<p>
					Todos los contenidos del sitio Web, como textos, fotografías,
					gráficos, imágenes, iconos, tecnología, software, así como su diseño
					gráfico y códigos fuente, constituyen una obra cuya propiedad
					pertenece a KUFLOW, sin que puedan entenderse cedidos al Usuario
					ninguno de los derechos de explotación sobre los mismos más allá de lo
					estrictamente necesario para el correcto uso de la Web. En definitiva,
					los usuarios que accedan a este sitio Web pueden visualizar los
					contenidos y efectuar, en su caso, copias privadas autorizadas siempre
					que los elementos reproducidos no sean cedidos posteriormente a
					terceros, ni se instalen a servidores conectados a redes, ni sean
					objeto de ningún tipo de explotación. Así mismo, todas las marcas,
					nombres o signos distintivos de cualquier clase que aparecen en el
					sitio Web son propiedad de KUFLOW, sin que pueda entenderse que el uso
					o acceso al mismo atribuya al Usuario derecho alguno sobre los mismos.
					Quedan prohibidas la distribución, modificación, cesión o comunicación
					pública de los contenidos y cualquier otro acto que no haya sido
					expresamente autorizado por el titular de los derechos de explotación.
				</p>
				<h2>3. Política de privacidad </h2>
				<p>
					Cuando precisemos obtener información por su parte, siempre le
					solicitaremos que nos la proporcione voluntariamente de forma expresa
					a través de los formularios de recogida de datos existentes en la Web.
					En el caso de que el usuario facilite datos de carácter personal de
					personas distintas al usuario se compromete a informar previamente, a
					las personas de las cuales facilita los datos personales, del
					contenido de la presente política de protección de datos, facilitando
					a los mismos, si fuera necesario, copia impresa de la misma.
					Igualmente, cuando los datos se refieran a un menor de edad y sean
					proporcionados por el mismo, KUFLOW supondrá que él mismo cuenta con
					el consentimiento de los tutores legales, quedando exenta de cualquier
					responsabilidad por el incumplimiento de este requisito. De
					conformidad con lo dispuesto en el art. 4 de la LOPDGDD, el usuario se
					compromete a facilitar datos verdaderos, exactos, completos y
					actualizados, de forma que sean veraces y respondan a la situación
					real del usuario. Los datos recabados a través de los formularios y
					del correo electrónico que usted nos remita solicitando información
					sobre nuestros servicios serán incorporados a un fichero automatizado
					de datos de carácter personal con el nombre de “USUARIOS WEB” del cual
					es responsable KUFLOW que tratará los datos de forma confidencial y
					exclusivamente con la finalidad de ofrecer los servicios solicitados y
					la remisión de información de nuestros servicios y proyectos, con
					todas las garantías legales y de seguridad que impone con todas las
					garantías legales y de seguridad que impone el Reglamento (UE)
					2016/679 de Protección de Datos RGPD, la Ley Orgánica 3/2018, de 5 de
					diciembre, de Protección de Datos Personales y Garantía de los
					Derechos Digitales LOPDGDD y la Ley 34/2002, de 11 de julio, de
					Servicios de la Sociedad de la Información y Comercio Electrónico
					LSSI-CE.
				</p>
				<h3>Finalidad ¿Con qué finalidades tratamos sus datos?</h3>
				<p>
					KUFLOW informa al Usuario de la existencia de un tratamiento de datos
					de carácter personal creado con los datos obtenidos en la página web
					por y para KUFLOW y bajo su responsabilidad.
				</p>
				<table>
					<tr>
						<th>.</th>
						<th>Fines de tratamiento</th>
					</tr>
					<tr>
						<td>I</td>
						<td>
							Posibilitar a los usuarios la navegación por la misma, permitiendo
							con ello el acceso a la información y a los contenidos dispuestos
							en la Plataforma.
						</td>
					</tr>
					<tr>
						<td>II</td>
						<td>
							Contactar con el usuario, dar respuesta a las solicitudes,
							peticiones o consultas, que pudieran derivarse de la utilización
							de los distintos formularios de la Plataforma.
						</td>
					</tr>
					<tr>
						<td>III</td>
						<td>
							Adoptar cuantas medidas de protección resulten aplicables de
							conformidad con la normativa vigente, incluyendo, la posible
							anonimización de sus datos personales aplicando, para ello, las
							adecuadas técnicas disponibles al efecto. Por consiguiente, en
							este ámbito, también podrán realizarse tratamientos de
							anonimización y seudonimización para la mejor protección de sus
							datos personales.
						</td>
					</tr>
				</table>
				<h3>Plazos ¿por cuánto tiempo conservaremos sus datos personales?</h3>
				<p>
					Los datos personales proporcionados se conservarán por el plazo
					determinado en base los siguientes criterios:
				</p>
				<table>
					<tr>
						<th>.</th>
						<th>Criterios de conservación</th>
					</tr>
					<tr>
						<td>I</td>
						<td>
							Con carácter general, se conservarán tus datos a estos fines por
							el tiempo imprescindible y necesario para posibilitarte la
							correcta navegación y el uso de nuestra plataforma y de los
							contenidos dispuestos a través de la misma a los que accedes.
							Respecto a los datos asociados a tu perfil de navegación, por
							relación a las cookies de tipo analíticas que hayas aceptado según
							se indica en la <Link to='/en/cookies'>Política de cookies</Link>.
						</td>
					</tr>
					<tr>
						<td>II</td>
						<td>
							Por el tiempo imprescindible para atender de forma correcta sus
							peticiones y/o solicitudes específicas según cada caso.
						</td>
					</tr>
					<tr>
						<td>III</td>
						<td>
							Mientras se traten datos personales del usuario, incluida la
							conservación de tales datos durante los plazos legales dispuestos,
							y con independencia de la base legítima de tratamiento.
						</td>
					</tr>
				</table>
				<h3>
					Legitimación ¿Cuál es la legitimación para el tratamiento de sus
					datos?
				</h3>
				<p>
					La base legal para el tratamiento de los datos personales de los
					usuarios es la relación contractual y el propio consentimiento del
					interesado.
				</p>
				<table>
					<tr>
						<th>Fines</th>
						<th>Base legítima de tratamiento</th>
					</tr>
					<tr>
						<td>Posibilitar a los usuarios la navegación por la Plataforma</td>
						<td>
							Su consentimiento y, según los casos, satisfacción del interés
							legítimo, propio o de terceros, asociado a la adecuada gestión,
							mantenimiento, desarrollo y evolución de la plataforma,
							herramientas, red y sistemas de la información asociados,
							permitiendo su correcto funcionamiento, funcionalidades, acceso a
							contenidos y servicios, así como la seguridad general de todos los
							anteriores extremos.
						</td>
					</tr>
					<tr>
						<td>
							Contactar con el usuario, dar respuesta a las solicitudes,
							peticiones o consultas, que pudieran derivarse de la utilización
							de los distintos formularios de la Plataforma.
						</td>
						<td>Su consentimiento.</td>
					</tr>
					<tr>
						<td>
							Adoptar cuantas medidas de protección resulten aplicables de
							conformidad con la normativa vigente, incluyendo, la posible
							seudonimización y anonimización de tus datos personales aplicando,
							para ello, las adecuadas técnicas disponibles al efecto.
						</td>
						<td>
							Cumplimiento de una obligación legal (Reglamento Europeo de
							Protección de Datos) RGPD.
						</td>
					</tr>
				</table>
				<p>
					Asimismo, la oferta prospectiva de servicios está basada en el
					consentimiento que se solicita al usuario sin que ningún caso la
					retirada de este consentimiento condicione la ejecución de otras
					finalidades.
				</p>
				<h3>Destinatarios ¿A qué destinatarios se comunicarán sus datos?</h3>
				<p>
					Los datos que se recogen en esta web mediante los formularios
					correspondientes serán objeto de comunicación a otras empresas que
					ejercen de encargados del tratamiento de datos personales, y que solo
					tratarán los datos conforme a las estrictas indicaciones que KUFLOW
					establece con dichos encargados. En todo caso los datos serán
					comunicados dentro del espacio de la Unión Europea y solo para los
					fines anteriormente indicados. En el marco de las comunicaciones
					indicadas en el párrafo anterior, se podrán realizar transferencias
					internacionales de datos a terceros países u organizaciones
					internacionales, sobre los que exista una decisión de adecuación de la
					Comisión Europea respecto a los mismos.{' '}
				</p>
				<h3>
					Derechos ¿Cuáles son sus derechos cuando nos facilita sus datos?
				</h3>
				<p>
					A los usuarios de la web titularidad de KUFLOW se les garantiza los
					derechos que se establecen en la legislación en materia de Protección
					de Datos Personales:
					<br />
					En primer lugar, el usuario tiene derecho a obtener una confirmación
					sobre si en KUFLOW se están tratando datos personales que le
					conciernan o no.
					<br />
					Los usuarios tendrán derecho a acceder a sus datos personales, así
					como a solicitar la rectificación de los datos que sean inexactos o,
					en su caso, solicitar su supresión cuando, entre otros motivos, los
					datos ya no sean necesarios para los fines que fueron recogidos.
					<br />
					Los usuarios tienen derecho a oponerse al tratamiento de sus datos
					personales, por lo que KUFLOW dejará de tratar sus datos, salvo si
					existen motivos legítimos, imperiosos, o el ejercicio o la defensa de
					posibles reclamaciones.
					<br />
					Los usuarios tienen la facultad de solicitar a KUFLOW que limite los
					tratamientos de sus datos personales cuando se cumplan los requisitos
					que el Reglamento UE 2016/679 RGPD exige.
					<br />
					Los usuarios tienen derecho a recibir en un formato estructurado, de
					uso común y lectura mecánica los datos personales que le incumban y
					que haya facilitado a KUFLOW, o solicitar a KUFLOW que los transmita
					directamente a otro responsable cuando sea técnicamente posible.
					<br />
					Por último, informamos que cualquier interesado podrá presentar una
					reclamación ante la Agencia Española de Protección de Datos,
					especialmente cuando no haya obtenido satisfacción en el ejercicio de
					sus derechos.
					<br />
					KUFLOW informa al usuario de las finalidades para las que empleará los
					datos personales recabados en esta Web en los diversos formularios:
				</p>
				<h4>3.1 Formularios de Registro y de Contacto. </h4>
				<p>
					KUFLOW, le informa que su dirección de correo electrónico y demás
					datos proporcionados serán introducidos en un fichero automatizado con
					la finalidad de proporcionarles información de nuestros servicios y
					proyectos o cualquier otra información que se nos solicite por e-mail.
					De conformidad con lo establecido en los párrafos anteriores KUFLOW se
					compromete a no ceder, comunicar, ni compartir los datos con terceros
					sin su aprobación expresa. Asimismo, KUFLOW cancelará o rectificará
					los datos cuando resulten inexactos, incompletos o hayan dejado de ser
					necesarios o pertinentes para su finalidad, de conformidad con lo
					previsto en el Reglamento UE 2016/679 Europeo de Protección de Datos
					RGPD y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de
					Datos Personales y Garantía de los Derechos Digitales LOPDGDD.{' '}
				</p>
				<h4>3.2 Inscripción lista de distribución ofertas y promociones. </h4>
				<p>
					KUFLOW le informa que su dirección de correo electrónico y demás datos
					proporcionados serán introducidos en un fichero automatizado (lista de
					distribución) con la finalidad de proporcionarles información de
					nuestras noticias y ofertas por e-mail. De conformidad con lo
					establecido en los párrafos anteriores KUFLOW se compromete a no
					ceder, vender, ni compartir los datos con terceros sin su aprobación
					expresa. Asimismo, KUFLOW cancelará o rectificará los datos cuando
					resulten inexactos, incompletos o hayan dejado de ser necesarios o
					pertinentes para su finalidad, de conformidad con lo previsto en la
					normativa vigente en protección de datos. El Usuario podrá revocar el
					consentimiento prestado y ejercer los derechos de acceso,
					rectificación, cancelación, oposición, limitación, supresión y
					portabilidad dirigiéndose a tal efecto a la dirección abajo indicada,
					identificándose debidamente e indicando de forma visible el concreto
					derecho que se ejerce, en la propia web o a través de correo.
					<br />
					KUFLOW adopta los niveles de seguridad correspondientes requeridos por
					el Reglamento de Medidas de Seguridad aprobado por el Real Decreto
					1720/2007, de 21 de diciembre. No obstante, KUFLOW no asume ninguna
					responsabilidad por los daños y perjuicios derivados de alteraciones
					que terceros pueden causar en los sistemas informáticos, documentos
					electrónicos o ficheros del usuario. KUFLOW podrá utilizar “cookies”
					durante la prestación de servicios del sitio web. Los “cookies” son
					ficheros físicos de información personal alojados en el propio
					Terminal del usuario. El usuario tiene la posibilidad de configurar su
					programa navegador de manera que se impida la creación de archivos
					cookie o se advierta de la misma.
					<br />
					Nuestra política con respecto al correo electrónico se centra en
					remitir únicamente comunicaciones que usted haya consentido recibir.
					Si prefiere no recibir estos mensajes por correo electrónico le
					ofreceremos a través de los mismos la posibilidad de ejercer su
					derecho de cancelación y renuncia a la recepción de estos mensajes, en
					conformidad con lo dispuesto en el Art. 22 de la Ley 34/2002 de
					Servicios para la Sociedad de la Información y de Comercio electrónico
					LSSICE.
				</p>
				<h2>
					4. Procedimiento en caso de realización de actividades de carácter
					ilícito
				</h2>
				<p>
					En el caso de que cualquier usuario o un tercero considere que existen
					hechos o circunstancias que revelen el carácter ilícito de la
					utilización de cualquier contenido y/o de la realización de cualquier
					actividad en las páginas Web incluidas o accesibles a través del sitio
					Web, deberá enviar una notificación a KUFLOW identificándose
					debidamente, especificando las supuestas infracciones y declarando
					expresamente y bajo su responsabilidad que la información
					proporcionada en la notificación es exacta.
				</p>
				<h2>5. Datos de Contacto</h2>
				<p>
					Todas las notificaciones y comunicaciones entre los usuarios y KUFLOW
					se considerarán eficaces, a todos los efectos, cuando se realicen a
					través de correo postal o correo electrónico o comunicación
					telefónica.
				</p>
				<ul>
					<li>
						Dirección: C/ Juan Ravina Méndez, 7, Planta 2, 38009 Santa Cruz de
						Tenerife.
					</li>
					<li>Teléfono: 922 20 16 17</li>
					<li>Correo electrónico de contacto: dpo@kuflow.com</li>
				</ul>
				<p>
					El usuario podrá contactar con el Departamento de Protección de Datos
					para resolver sus dudas relacionadas con el tratamiento de sus datos
					personales en la misma dirección de correo postal facilitada.
				</p>

				<h2>6. Modificación y actualización de la política</h2>
				<p>
					La presente política podrá ser modificada en cualquier momento, siendo
					debidamente publicada en este mismo sitio, por causa de cambio de
					requisitos legales, cambios jurisprudenciales y, en general, por
					cambios en la actuación y estrategia de KUFLOW. La publicación y
					acceso por parte de los usuarios, será realizada a través de este
					mismo sitio, entendiéndose que las relaciones establecidas con los
					mismos, con anterioridad al cambio, se regirán por las normas
					previstas en el momento en que se accedió al sitio Web para su
					establecimiento.
				</p>

				<h2>7. Política de Seguridad de la Información</h2>
				<p>
					<Link to='/resources/PSI_vPublic.pdf'>Política de Seguridad de la Información (ENS)</Link>
				</p>
			</div>
		</LayoutEN>
	);
}
